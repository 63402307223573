import type { NavigateOptions } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { useMemo } from 'react';

import type {
  ChatSessionId,
  KnowledgeBaseFileId,
  KnowledgeBaseFolderId,
  KnowledgeBaseId,
  KnowledgeBaseLayerId,
} from '@/data/commons';
import type { SubscriptionTier } from '@/generated/clients/ludus-service-client/requests';
import { useRouter } from '@/libs/i18nNavigation';

interface IQueryString {
  key: string;
  value?: string | string[];
}

export type INavigateOptions = NavigateOptions & {
  newTab?: boolean;
};

const createQueryString = (queryStrings: IQueryString[]): string => {
  const params = new URLSearchParams();

  for (const queryString of queryStrings) {
    const { key, value } = queryString;

    if (value) {
      if (Array.isArray(value)) {
        for (const queryElement of value) {
          params.append(key, queryElement);
        }
      } else {
        params.append(key, value);
      }
    }
  }

  return params.toString();
};

export const ROUTES = {
  ROOT: '/',
  TERMS: '/legal/terms',
  LAYER_HELP: '/layers',
  CONTACT: '/contact',
  PRIVACY: '/legal/privacy',
  AUTH_HOME: '/home', // don't change this unless you also change the clerk protected auth directory and nextjs folder paths
  KNOWLEDGE_HUB: `/home/hub`,
  MANAGE_LAYERS: `/home/layers/manage`,
  LAYER: ({ layerId }: { layerId: KnowledgeBaseLayerId }) => {
    return `${ROUTES.AUTH_HOME}/layers/${layerId}`;
  },
  EXPLORER: ({
    kbId,
    folderId,
  }: {
    kbId: KnowledgeBaseId;
    folderId?: KnowledgeBaseFolderId | null;
  }) => {
    let route = `${ROUTES.AUTH_HOME}/explorer/knowledge-bases/${kbId}`;

    if (folderId) {
      route = `${route}/folder/${folderId}`;
    }

    return route;
  },
  FILE_VIEWER: ({
    kbId,
    fileId,
    page,
    highlight,
  }: {
    kbId: KnowledgeBaseId;
    fileId: KnowledgeBaseFileId;
    page?: number;
    highlight?: string;
  }) => {
    const searchParams = new URLSearchParams();

    if (page) {
      searchParams.append('page', String(page));
    }

    if (highlight) {
      searchParams.append('highlight', highlight);
    }

    return `${ROUTES.AUTH_HOME}/explorer/knowledge-bases/${kbId}/file/${fileId}${searchParams.size > 0 ? `?${searchParams.toString()}` : ''}`;
  },
  SEARCH: (params?: {
    query?: string;
    kbIds?: string[];
    sessionId?: ChatSessionId;
  }) => {
    if (params) {
      const { kbIds, query, sessionId } = params;

      return `${ROUTES.AUTH_HOME}?${createQueryString([
        { key: 'kbId', value: kbIds },
        { key: 'query', value: query },
        {
          key: 'sessionId',
          value: sessionId,
        },
      ])}`;
    }

    return `${ROUTES.AUTH_HOME}`;
  },
  CHECKOUT: ({ tier }: { tier: SubscriptionTier }) => {
    return `${ROUTES.AUTH_HOME}/checkout/${tier}`;
  },
};

export function useRoutes() {
  const router = useRouter();

  return useMemo(() => {
    return {
      goToLayerHelp: (options?: INavigateOptions) => {
        if (options?.newTab) {
          window.open(ROUTES.LAYER_HELP, '_blank', 'noopener,noreferrer');
        } else {
          router.push(ROUTES.LAYER_HELP, options);
        }
      },
      goToKnowledgeHub: () => {
        router.push(ROUTES.KNOWLEDGE_HUB);
      },
      goToLayer: (params: { layerId: KnowledgeBaseLayerId }) => {
        router.push(ROUTES.LAYER(params));
      },
      goToExplorer: (
        params: {
          kbId: KnowledgeBaseId;
          folderId?: KnowledgeBaseFolderId | null;
        },
        options?: INavigateOptions,
      ) => {
        router.push(ROUTES.EXPLORER(params), options);
      },
      goToFileViewer: (
        params: {
          kbId: KnowledgeBaseId;
          fileId: KnowledgeBaseFileId;
          page?: number;
        },
        options?: INavigateOptions,
      ) => {
        router.push(ROUTES.FILE_VIEWER(params), options);
      },
      goToSearch: (
        params?: { sessionId?: ChatSessionId },
        options?: INavigateOptions,
      ) => {
        router.push(ROUTES.SEARCH(params), options);
      },
      goToCheckout: (
        { tier }: { tier: SubscriptionTier },
        options?: INavigateOptions,
      ) => {
        if (options?.newTab) {
          window.open(
            ROUTES.CHECKOUT({ tier }),
            '_blank',
            'noopener,noreferrer',
          );
        } else {
          router.push(ROUTES.CHECKOUT({ tier }), options);
        }
      },
      goToCustomerPortal: ({ email }: { email?: string }) => {
        let customerPortalLink =
          process.env.NEXT_PUBLIC_STRIPE_CUSTOMER_PORTAL_URL;

        if (email) {
          customerPortalLink = `${customerPortalLink}?prefilled_email=${email}`;
        }
        window.open(customerPortalLink, '_blank', 'noopener,noreferrer');
      },
    };
  }, [router]);
}
